export const images = [
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20546-1200x1000.jpg%3Frev%3D13a3d0ecd8ab482c895be5fda8ec190f&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20513-1200x1000.jpg%3Frev%3D235a138ff1aa40b88d7f1b2dbb2ef082&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw22-21313-1200x1000.jpg%3Frev%3Da746e31ae18d44d4b7aaacbb5fd491a7&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw19-18672-1200x1000.jpg%3Frev%3D6d399e46863b4b66bac06851eff6d680&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw22-21308-1200x1000.jpg%3Frev%3D327e3a9f38ce405ba6a744ae2e3ea7b8&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw20-19364-1200x1000.jpg%3Frev%3Dec45da132ba04516b38a47ab02d88b4b&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw19-18727-1200x1000.jpg%3Frev%3Dedbaac222a004b418d4cf18b819ac0ee&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20776-1200x1000.jpg%3Frev%3D0b2be86276ea4277bdacdda00637d7de&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20776-1200x1000.jpg%3Frev%3D0b2be86276ea4277bdacdda00637d7de&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20985-1200x1000.png%3Frev%3D09ca6b2a0d5346d1aebccb49cb6147cb&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw20-18914-1200x1000.jpg%3Frev%3Dad678f9b765d4e24aae7d28d9f038dff&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw12_645_1200x1000.jpg%3Frev%3D357e4518370a460d9185976da63c3f5d&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20935-1200x1000.jpg%3Frev%3Dd44db9d13ecf410c9f146f686842268b&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw09_072_1200x1000.jpg%3Frev%3D29810f26d1f840f498ba317d833dfac1&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw10_525_1200x1000.jpg%3Frev%3Dabe3603aa65c421cb4794546b5431dca&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw14-12234_1200x1000.jpg%3Frev%3D7c407f379a9c4abf89004c1e3381e873&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20546-1200x1000.jpg%3Frev%3D13a3d0ecd8ab482c895be5fda8ec190f&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-20513-1200x1000.jpg%3Frev%3D235a138ff1aa40b88d7f1b2dbb2ef082&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw17_16763-1200x1000.jpg%3Frev%3D36d231cfd53b43058255af9a7542aae0&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw18_17510-1200x1000.jpg%3Frev%3Dd4c08d2ab8e743c687a4800d09f9228e&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw17_17306-1200x1000.jpg%3Frev%3D98fd130ef05f45c0998befc84a788246&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw17_16879-1200x1000.jpg%3Frev%3D769e52ef56ab43a189dd5308f05d2f09&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw14-12833-1200x1000.jpg%3Frev%3Da6ebc8b4e4724d8cab4419777f1c3586&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-06-06-22%2Faw21-21108-1200x1000.jpg%3Frev%3D86c1230a0399410e9f792fcee6aa5b0a&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw13-11456_1200x1000.jpg%3Frev%3D161549450eae40ffa54fe42987ef422f&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw13-11456_1200x1000.jpg%3Frev%3D161549450eae40ffa54fe42987ef422f&w=3840&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw18_17508-1200x1000.jpg%3Frev%3D9f8e17ea9cf7494aa5e5f3c26f588632&w=2048&q=75",
      "https://www.andersenwindows.com/_next/image/?url=https%3A%2F%2Fedge.sitecorecloud.io%2Fandersencorporation-c47i754m%2Fmedia%2FProject%2FAndersenCorporation%2FAndersenWindows%2FAndersenWindows%2Fimages%2Fideas-and-inspiration%2Fphoto-gallery%2Fphoto-gallery-updated-05-11-22%2Faw14-12852-1200x1000.jpg%3Frev%3De864dc8f263d47d09cd856e334ced754&w=2048&q=75",
    ]