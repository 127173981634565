import { useEffect, useState } from "react";
import { marked } from 'marked';

const renderer = new marked.Renderer();
//@ts-ignore
renderer.link = function (href: any, title: any, text: any) {
  //@ts-ignore
  const link = marked.Renderer.prototype.link.apply(this, arguments);
  return link.replace("<a", "<a target='_blank'");
};

marked.setOptions({
    renderer: renderer
});

const truncateRegex = /[.?!]|\n-/
function needsTruncation(str: string, num: number){
  const lines = str.split(truncateRegex).filter(s => s && s.length > 0);
  const numberOfLines = lines.length; 
  return numberOfLines > num;
}

function truncateString(str: string, num: number) {
  let holdingStr = str;
  let numberOfLine = num;
  const displayArray = [];
  let indexOfPunc = holdingStr.search(truncateRegex);
  
  while (indexOfPunc > 0 &&  numberOfLine > 0){
    displayArray.push(holdingStr.substring(0, indexOfPunc + 1));
    holdingStr = holdingStr.slice(indexOfPunc + 1).trim();
    indexOfPunc = holdingStr.search(truncateRegex);
    numberOfLine--;
  }

  const finalStr = displayArray.join(' ').trim() + "..."
  return finalStr;
}

export function useTruncate(reasoning: string | undefined, numberOfLines: number){
    const [description, setDescription] = useState<string>(reasoning ?? "");
    const [shouldEnableTruncate, setShouldEnableTruncate] = useState<boolean>(needsTruncation(description, numberOfLines));
    const [shouldTruncate, setShouldTruncate] = useState<boolean>(true);

    useEffect(() =>{
      let descr = reasoning ?? "";

    const enableTruncation = needsTruncation(descr, numberOfLines);
    setShouldEnableTruncate(enableTruncation);

    if(enableTruncation && shouldTruncate){
      descr = truncateString(descr, numberOfLines).replaceAll("- ", "\n- "); 
    }

    setDescription(marked.parse(descr) as string);
    }, [reasoning, shouldTruncate, numberOfLines])


    return {
        shouldEnableTruncate,
        shouldTruncate,
        setShouldTruncate,
        description
    }
}