import { Tabs, TabList, Tab } from '@chakra-ui/react'

interface FilterTabsProps {
  sectionRefs: React.MutableRefObject<any[]> 
  tabIndex: number,
  onChange: Function 
}

export default function FilterTabs(props:  FilterTabsProps){
    const handleTabsChange = (index: number) => {
        props.onChange(index)
        props.sectionRefs?.current[index].scrollIntoView()
      }
    


    return (
      <Tabs
        index={props.tabIndex}
        onChange={handleTabsChange}
        colorScheme="orange"
        size="lg"
      >
        <TabList
          style={{
            display: 'flex',
            flexGrow: 1,
            padding: '0 32px',
            gap: '8px'
          }}
        >
          <Tab>Search</Tab>
          <Tab>Project</Tab>
          <Tab>Cost</Tab>
          <Tab>Color</Tab>
          <Tab>Region</Tab>
          <Tab>Type</Tab>
        </TabList>
      </Tabs>
    );
}