import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faHeart } from "@fortawesome/free-solid-svg-icons";

import styles from './Header1.module.css'

export function Header1(){ 
    return (
        <nav className={styles.nav1}>
          <ul>
            <li className={styles.nav1link}>
              <a href="https://www.andersenwindows.com/windows-and-doors/your-perfect-match/favorites">
                <FontAwesomeIcon icon={faHeart} 
                  style={{
                    width: '12px',
                    height: '12px',
                    marginRight: '1px'
                  }}
                /> My Favorites (0)
              </a>
            </li>
            <li className={styles.nav1link}>
              <a href="https://www.andersenwindows.com/support/contact-us/">
                Contact Us
              </a>
            </li>
            <li className={styles.nav1link}>
              <a href="https://locations.andersenwindows.com/where-to-buy/">
                Where to Buy
              </a>
            </li>
            <li className={styles.nav1link}>
              <a href="https://locations.andersenwindows.com/andersen-certified-contractors">
                Find A Contractor
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} 
                  style={{
                    width: '12px',
                    height: '12px',
                    paddingLeft: '2px',
                    marginLeft: '2px'
                  }}
                />
              </a>
            </li>
            <li className={styles.nav1link}>
              <a href="https://www.andersenwindows.com/for-professionals/certified-contractor/">
                Become A Certified Contractor
              </a>
            </li>
            <li className={styles.nav1link}>|</li>
            <li className={styles.nav1link}>
              <a className={styles.link} href="tel:+18446617003">
                1-844-661-7003
              </a>
            </li>
          </ul>
        </nav>
    )
}