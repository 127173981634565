/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './CTAButton.module.css'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { classNameJoiner } from '../../utils'
import { motion, TargetAndTransition, VariantLabels } from 'framer-motion'

interface CTAButtonProps {
    shouldShowArrow?: boolean
    children?: React.ReactNode
    className?: string
    onClick?: Function
    onHover?:  VariantLabels | TargetAndTransition | undefined
    disabled?: boolean
}

export function CTAButton(props: CTAButtonProps){
    return (
      <motion.button 
        className={classNameJoiner([styles.link, props.className ?? ''])}
        onClick={() => props.onClick && props.onClick()}
        whileHover={props.onHover ?? {
          backgroundColor: 'rgb(242, 105, 36)',
          color: 'white',
          gap: '7px'
        }}
        disabled={props.disabled ?? false}
        type="button"
      >
        {props.children ? props.children : <a href="#">Request a quote</a>}
        {props.shouldShowArrow && <FontAwesomeIcon icon={faArrowRight} />}
      </motion.button>
    );
}