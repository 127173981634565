/* eslint-disable jsx-a11y/anchor-is-valid */
import { CTAButton } from '../CTAButton'
import ImageToShow from './image.png'
import styles from './FeelingStuck.module.css';

export function FeelingStuck(){
    return (
      <div className={styles.container}>
        <img src={ImageToShow} alt="Feeling stuck?" />
        <div className={styles.textArea}>
          <div>
            <h2>Feeling stuck?</h2>
            <p>
              Our experts are here to help you find the perfect products for
              your project.
            </p>
          </div>
          <CTAButton shouldShowArrow className={styles.cta}>
            <a href="#">Schedule a consultation</a>
          </CTAButton>
        </div>
      </div>
    );
}