import { motion, useInView  } from 'framer-motion';

import styles from './Section.module.css'
import { PropsWithChildren, useEffect, useRef } from 'react';

interface SectionProps {
   refProp: any,
   sectionName: string
   onView?: Function 
   style?: any
   setIsInView: Function
}

export function Section(props: PropsWithChildren<SectionProps>){
  const ref = useRef(null)
  const isInView = useInView(ref);

  useEffect(() => {
    props.setIsInView(isInView);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <div className={styles.section} ref={props.refProp}>
      <h3 ref={ref}>{props.sectionName}</h3>
      <motion.div
        //@ts-ignore
        // onViewportEnter={props.onView}
        className={styles.trackerBox}
      />
      <div className={styles.iconsContainer} style={props.style} >
        {props.children}
      </div>
    </div>
  );
}