import React, { Suspense } from 'react';
import { HomePageModal } from '../../components/HomePageModal';
import styles from './Home.module.css'
import { images } from './images';
import { motion } from 'framer-motion';

function BackgroundImages(){

    const [results, setResults] = React.useState([]);

    React.useEffect(() => {
      const imgs = images.map((image, i) => (
        <Suspense>
          <motion.img
            src={image}
            alt="background"
            style={{
              gridRow: `span ${(i % 2) + 1}`,
            }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 100, y: 10 }}
            transition={{ ease: "easeIn", duration: 2 }}
          />
        </Suspense>
      ));

      setResults(imgs as any);
    }, [])

  return (
    <div
    className={styles.imagesContainer}
    >
      {results}
    </div>
  )
}

export function Home(){
  return (
      <main style={{ height: "100%" }}>
        <BackgroundImages />
        <HomePageModal />
      </main>
  )
}