/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import styles from './App.module.css';
import { Header1, Header2 } from './components/Header';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Explore, Home } from './pages';

function App() {
  return (
    <div className={styles.App}>
      <Router>
        <header className={styles.AppHeader}>
          <Header1 />
          <Header2 />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/explore" element={<Explore />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
