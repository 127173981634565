import { Suspense } from "react";
import { classNameJoiner } from '../../utils';
import { motion } from "framer-motion";

import styles from './Tile.module.css'

interface TileProps {

  imageUri?: string,
  imageStyles?: object,
  title: string,
  isActivated?: boolean,
  setActivation?: Function,
}

export function Tile(props: TileProps){
  return (
    <div
      className={classNameJoiner([
        styles.tileContainer,
        (props.isActivated && styles.tileActivated) || "",
      ])}
      onClick={() => props.setActivation && props.setActivation()}
    >
      {props.imageUri && (
        <Suspense>
          <motion.img
            src={props.imageUri}
            alt="placeholder"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeIn", duration: 0.5 }}
            style={props.imageStyles}
          />
        </Suspense>
      )}
      <p>{props.title}</p>
    </div>
  );
}