/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import styles from './HomePageModal.module.css'
import { Link } from 'react-router-dom';
import { CTAButton } from '../CTAButton';
import { motion } from 'framer-motion';

export function HomePageModal() {

  return (
    <motion.div className={styles.modalContainer}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{ease: 'easeIn', duration: 1}}
    >
      <img
        alt="Andersen Corporation Logo"
        src="https://edge.sitecorecloud.io/andersencorporation-c47i754m/media/Project/AndersenCorporation/AndersenWindows/AndersenWindows/images/brand-launch-2020/andersen_logo_tm_rectangle_rgb.svg?h=260&iar=0&w=1440&rev=04a11c712c8e4f5cb1812cec160e6fa6"
      />
      <div className={styles.modalText}>
        <h2>Find the perfect window for you</h2>
        <p>
          Select as many attributes as you’d like and receive product
          recommendations each step of the way.
        </p>
      </div>

      <CTAButton shouldShowArrow className={styles.cta}>
        <Link to="/explore">Get started</Link>
      </CTAButton>
    </motion.div>
  );
}