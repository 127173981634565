
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import styles from './Header2.module.css';
import { Link } from 'react-router-dom';
import { CTAButton } from '../../CTAButton';

export function Header2() {

    return (
        <nav className={styles.nav2}>
            <Link
            to={'/'}
            >
                <img
                    alt="Andersen Corporation Logo"
                    src="https://edge.sitecorecloud.io/andersencorporation-c47i754m/media/Project/AndersenCorporation/AndersenWindows/AndersenWindows/images/brand-launch-2020/andersen_logo_tm_rectangle_rgb.svg?h=260&iar=0&w=1440&rev=04a11c712c8e4f5cb1812cec160e6fa6"
                    className={styles.logo}
                />
            </Link>

          <ul>
            <li className={styles.link}>
              <a href="#">Windows & Doors</a>
            </li>
            <li className={styles.link}>
              <a href="#">Inspiration</a>
            </li>
            <li className={styles.link}>
              <a href="#">Parts & Support</a>
            </li>
            <li className={styles.link}>
              <a href="#">For Professionals</a>
            </li>
            <li>
              <CTAButton />
            </li>
            <li className={`${styles.link} ${styles.mag}`}>
              <a href="#" 
                style={{
                  backgroundColor: '#F8F6F4',
                  padding: '10px',
                  borderRadius: '100%',
                  display: 'flex'

                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} 
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />

              </a>
            </li>
          </ul>
        </nav>
    )
}