import styles from './RecommandationContext.module.css'

import AndersonLogo from '../../images/andersonLogo.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { WindowSuggestion } from "../WindowSuggestion";
import { APIResult } from "../../interfaces";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from 'framer-motion';
import { useTruncate } from '../../hooks';

interface RecommandationContextProps{
  isLoaded?: boolean
  reasoning?: string
  heroResult?: APIResult
}


  export function RecommandationContext(props: RecommandationContextProps){
    const {
      description,
      shouldEnableTruncate,
      shouldTruncate,
      setShouldTruncate,
    } = useTruncate(props?.reasoning, !!props.heroResult ? 3 : 4);

    if(!props.isLoaded || !props.reasoning){
      return null;
    }

    return (
      <motion.div
        className={styles.container}
        initial={{ opacity: 0, x: -1 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -1 }}
        // exit={{ opacity: 0, scale: 0.5  }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <div className={styles.header}>
          <div className={styles.imgContainer}>
            <img
              src={AndersonLogo}
              alt="Andersen Logo"
              height="24px"
              width="24px"
            />
            <p>Andersen Window Finder</p>
          </div>
          <span className={styles.heroWindowRecommendation}>
            <FontAwesomeIcon icon={faStar} style={{ fontSize: "14px" }} />
            Smart Recommendation
          </span>
        </div>
        {props.isLoaded && props?.reasoning && (
          <>
            <div
              className={styles.reasoningContainer}
              style={{
                padding: props.heroResult ? "24px 0" : undefined,
              }}
            >
              <p
                className={styles.reasoning}
                dangerouslySetInnerHTML={{
                  //@ts-ignore
                  __html: description,
                }}
              />
              {shouldEnableTruncate && (
                <button onClick={() => setShouldTruncate((prev) => !prev)}>
                  {shouldTruncate ? "Show more" : "Show less"}
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    transform={{ rotate: !shouldTruncate ? 180 : 0 }}
                  />
                </button>
              )}
            </div>
            {props.heroResult && (
              <WindowSuggestion
                isHero
                isLoaded={props.isLoaded}
                result={props.heroResult}
                reasoning={props.heroResult.description}
                key={"Hero Suggestion"}
              />
            )}
          </>
        )}
      </motion.div>
    );
  }