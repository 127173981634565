/* eslint-disable jsx-a11y/anchor-is-valid */
import { Skeleton, Text } from '@chakra-ui/react'

import { CTAButton } from '..';
import styles from './WindowSuggestions.module.css'
import { APIResult  } from '../../interfaces';

import AndersonRenewal from '../../images/anderson_renewal.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCaretDown, faStar as faSoildStar } from '@fortawesome/free-solid-svg-icons';
import { faStar, faStarHalfStroke } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTruncate } from '../../hooks';
import { classNameJoiner } from '../../utils';

function Tag(props: any){

  return (
    <div
      className={styles.tag}
    >{props.tag}</div>
  )
}

function Tags(props: any){

  return (
    <div className={styles.tags}>
      {props?.tags?.split(",").map((tag: string) => (
        <Tag tag={tag} key={tag}/>
      ))}
    </div>
  );
}
interface ButtonGroupProps {
  actionLink?: string
  viewLink?: string
  isHero?: boolean
}

function ButtonGroup({actionLink, viewLink, isHero}: ButtonGroupProps) {
  return (
    <div
      className={classNameJoiner([
        styles.actionButtons,
      ])}
      style={{
        paddingLeft: isHero ? 'initial' : '24px',
        paddingRight: isHero ? 'initial' : '24px',
      }}
    >
      <CTAButton
        // eslint-disable-next-line no-restricted-globals
        onClick={() => window.open(actionLink ?? "#", "_blank")}
        shouldShowArrow
      />
      <CTAButton
        shouldShowArrow
        className={styles.otherCTA}
        onHover={{
          backgroundColor: "var(--background-tertiary, #EEEDEC)",
          gap: "7px",
        }}
        // eslint-disable-next-line no-restricted-globals
        onClick={() => window.open(viewLink ?? "#", "_blank")}
      >
        View this window
      </CTAButton>
    </div>
  );
}

interface StarRatingProp {
  stars?: string
}

function StarRating(props: StarRatingProp){
  const [stars, setStars] = useState<any[]>([]);

  useEffect(() => {
    if(!props.stars){
      return
    }
    const starsRaw = parseFloat(props.stars);
    
    const starsWhole = parseInt(starsRaw + '');
    const starsRemaineder = parseFloat((starsRaw % 1).toFixed(2));

    const values =       
      [...Array(Math.min(starsWhole, 5))].map((_,i) =>{
      return <FontAwesomeIcon icon={faSoildStar} key={"whole-"+ i} style={{fontSize: '10px'}} />
    })

    let remainder = 5 - starsWhole;

    if( remainder > 0 && starsRemaineder > 0.4){
      values.push( <FontAwesomeIcon icon={faStarHalfStroke} key={"half-"} style={{fontSize: '10px'}} />)
      remainder--;
    }

    if (remainder > 0) {
      values.push(
        ...[...Array(remainder)].map((_, i) => {
          return <FontAwesomeIcon icon={faStar} key={"blank-"+ i} style={{ fontSize: "10px" }} />;
        })
      );
    }
  
    setStars(values)
  }, [props.stars])

  return (
  <div
    style={{
      display: 'flex',
      gap: '8px',
      fontSize: '14px',
      color: '#686869'
    }}
  >
    <div>
      {stars}
    </div>
    <span>{props.stars}</span>
  </div>
  )
}

interface WindowSuggestionProps {
  isLoaded?: boolean
  isHero?: boolean

  result?: APIResult
  reasoning?: string
}

export function WindowSuggestion(props: WindowSuggestionProps) {
    const {
      description,
      shouldEnableTruncate,
      shouldTruncate,
      setShouldTruncate,
    } = useTruncate(props?.result?.windowSummary, 2);

    if(!props.result && props.isHero){
      return null;
    }

    if (!props.isLoaded){

      return (<Skeleton height="365px" width={"400px"} />)
    }

    return (
      <div
        className={classNameJoiner([
          styles.windowContainer,
          (props.isHero && styles.windowHeroContainer) || "",
        ])}
      >
        <img src={props?.result?.imageUrl} alt={props?.result?.title} />
        <div
          className={classNameJoiner([
            styles.windowContent,
            (props.isHero && styles.heroContent) || "",
          ])}
        >
          <div className={styles.series}>
            {props.result?.isRenewal === "true" ||
            props.result?.isRenewal === true ? (
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                <img src={AndersonRenewal} alt="anderson revewal logo" />
                <Text fontSize="xs">RENEWAL BY ANDERSEN</Text>
              </div>
            ) : (
              <Text fontSize="xs">{props.result?.series}</Text>
            )}
            <StarRating stars={props.result?.starRating} />
          </div>

          <h3>{props?.result?.title}</h3>
          {(props?.result?.tags ?? "").length > 0 && (
            <Tags tags={props?.result?.tags ?? ""} />
          )}
          <p
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: description,
            }}
            className={styles.description}
          />
          {shouldEnableTruncate && (
            <motion.button
              className={styles.readMoreButton}
              onClick={() => setShouldTruncate((prev) => !prev)}
              whileHover={{
                scale: 1.02,
              }}
            >
              Show {shouldTruncate ? "more" : "less"}
              <FontAwesomeIcon
                icon={faCaretDown}
                transform={{ rotate: !shouldTruncate ? 180 : 0 }}
              />
            </motion.button>
          )}
          {!!props.isHero && (
            <span
              style={{
                paddingTop: "24px",
              }}
            >
              <ButtonGroup
                actionLink={props.result?.actionLink}
                viewLink={props.result?.viewLink}
                isHero
              />
            </span>
          )}
        </div>
        {!!!props.isHero && (
          <ButtonGroup
            actionLink={props.result?.actionLink}
            viewLink={props.result?.viewLink}
          />
        )}
      </div>
    );
}