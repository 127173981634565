import { useRef, useState } from "react";
import { CTAButton } from "../CTAButton";

import styles from './SearchArea.module.css'  
import { classNameJoiner } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

interface SearchAreaProps {
    onChange: Function
    isLoading: boolean
}

  export function SearchArea(props: SearchAreaProps) {
    const [text, setText] = useState('');
    const [triggered, setTriggered] = useState<boolean>(false);
    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    const triggerSearch = (value: string) => {
      setTriggered((prev) => {
        const newValue = !prev;

        if (newValue) {
          props.onChange(value);
        } else {
          setText("");
          props.onChange("");
        }

        return newValue;
      });
    };

    return (
      <div>
        <h2>What are you looking for?</h2>
        <motion.div
          className={classNameJoiner([
            styles.container,
            (triggered && styles.triggeredContainer) || "",
          ])}
          onClick={() => inputRef?.current?.focus()}
          layout="size"
          transition={{
            opacity: { ease: "linear" },
            layout: { duration: 0.3 },
          }}
        >
          <div className={styles.inputContainer}>
            <textarea
              placeholder="Describe what you are looking for"
              value={text}
              onChange={(e) => setText(e.target.value)}
              ref={inputRef}
              disabled={triggered}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  triggerSearch(text);
                }
              }}
            />
            {!triggered && (
              <CTAButton
                shouldShowArrow
                disabled={props.isLoading}
                className={classNameJoiner([styles.ctaButton])}
                onClick={() => triggerSearch(text)}
              >
                <span>Find</span>
              </CTAButton>
            )}
            {triggered && (
              <CTAButton
                disabled={props.isLoading}
                className={classNameJoiner([
                  styles.ctaButton,
                  styles.ctaButtonTriggered,
                ])}
                onHover={{
                  background: "var(--background-tertiary, #EEEDEC)",
                  color: "var(--text-primary, #000)",
                  gap: "7px",
                }}
                onClick={() => triggerSearch("")}
              >
                <span>New Search</span>
                <FontAwesomeIcon
                  icon={faArrowRotateLeft}
                  style={{ fontSize: "14px" }}
                />
              </CTAButton>
            )}
          </div>
          {!triggered && (
            <motion.span initial="hidden" animate="visible" variants={list}>
              <p>Try starting with an example</p>
              <motion.div
                className={styles.actionButtons}
                initial="hidden"
                animate="visible"
                variants={list}
              >
                <motion.button
                  onClick={() => {
                    setText("Energy efficient windows for old homes");
                    props.onChange("Energy efficient windows for old homes");
                    setTriggered(true);
                  }}
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  “Energy efficient windows for old homes”
                </motion.button>
                <motion.button
                  onClick={() => {
                    setText("I’m building a house");
                    props.onChange("I’m building a house");
                    setTriggered(true);
                  }}
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  “I’m building a house”
                </motion.button>
              </motion.div>
            </motion.span>
          )}
        </motion.div>
      </div>
    );
  }