
import styles from './Explore.module.css'
import React, {useEffect, useRef, useState } from 'react';
import { FeelingStuck, FilterTabs, RecommandationContext, SearchArea, Section, Tile, WindowSuggestion} from '../../components';

import Passthru from '../../images/filters/Pass-thru.svg'

import {
  Awning,
  BowAndBow,
  Casement,
  CSE,
  Double,
  DoubleHung,
  MW,
  NE,
  NewConstruction,
  Picture,
  PNW,
  Retrofit,
  SE,
  Specialty,
  SW,
} from "../../images/filters";


import { BudgetEnum, ColorOptionsEnum, LocationEnum, ProjectTypeEnum, WindowTypeEnum } from '../../enums';
import { APIRequest, APIResponse, APIResult } from '../../interfaces';
import { customColors, premiumColors, standardColors, woodFinish } from '../../images/colors';

const singleSelect = (enumVal: any, setter:Function) => () => 
                setter((prev: string) =>
                  prev !== enumVal 
                    ? enumVal
                    : undefined
                );

const mutiSelect = (value: any, setter: Function) => () =>
  setter((prev: string[]) => {
    if (prev.includes(value)) {
      return prev.filter((b) => b !== value);
    }
    return [...prev, value];
  });


export function Explore(){
  const sectionRefs: React.MutableRefObject<any[]> = useRef([])
  const [tabIndex, setTabIndex] = React.useState(0);

  const [userInput, setUserInput] = React.useState<string>("");
  const [projectType, setProjectType] = React.useState<ProjectTypeEnum | undefined>();
  const [budget, setBudget] = React.useState<BudgetEnum[]>([]);
  const [colorOptions, setColorOptions] = React.useState<ColorOptionsEnum[]>([]);
  const [location, setLocation] = React.useState<LocationEnum | undefined>();
  const [windowType, setWindowType] = React.useState<WindowTypeEnum[]>([]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reasoning, setReasoning] =React.useState<string>("");
  const [heroResult, setHeroResult] = React.useState<APIResult | undefined>();
  const [windowResults, setWindowResults] = React.useState<APIResult[]>();

  const [scrollTracker, setScrollTracker] = useState<boolean[]>([true, false, false, false, false, false]);
  
  const rightSideRef = useRef<HTMLDivElement | undefined>();

  useEffect(() => {
    const searchFn = (el: boolean) => el === true
    const indexDown = scrollTracker.findIndex(searchFn);
    // const indexUp = scrollTracker.findLastIndex(searchFn)
    // if (indexUp === scrollTracker.length -1) {
    //   setTabIndex(indexUp)
    // }else 
    if ( indexDown > -1){
      setTabIndex(indexDown);
    }
  }, [scrollTracker])

  const scrollIntoViewFn
  = (index: number) => (value: boolean) => {
    setScrollTracker(prev => {
      const next = [...prev];
      next[index] = value;
      return next;
    })
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const requestBody: APIRequest = {
      userInput: userInput,
      specifications: {
        projectType,
        budget,
        colorOptions,
        location,
        windowType,
      },
    };
    console.log(requestBody);

    const URL = "https://andersen-ai-demo.3kit.com/api/results";

    setHeroResult(undefined);
    setIsLoading(true);
    rightSideRef?.current?.scrollTo(0,0);
    fetch(URL, {
      signal,
      method: "POST",
      body: JSON.stringify(requestBody),
    })
    .then(r => r.json())
    .then((r: APIResponse) => {
      console.log('Result: ', r)
      setReasoning(r.agentResponse)
      setHeroResult(r?.heroResult);
      setWindowResults(r?.results)

      setIsLoading(false);
      rightSideRef?.current?.scrollTo(0,0);
    })
    .catch((e) => {
      if (e.name === 'AbortError'){
        //pass
      }
      else {
        setIsLoading(false);
      }
    })

    return () => controller.abort();

  }, [userInput, projectType, budget, colorOptions, location, windowType]);

  return (
    <main className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.heading}>
          <h2>Tell us about the perfect window for your home</h2>
        </div>
        <FilterTabs
          tabIndex={tabIndex}
          onChange={(value: number) => setTabIndex(value)}
          sectionRefs={sectionRefs}
        />

        <div className={styles.sections}>
          <Section
            refProp={(el: any) => (sectionRefs.current[0] = el)}
            onView={() => setTabIndex(0)}
            setIsInView={scrollIntoViewFn(0)}
            sectionName=""
            style={{
              gridTemplateColumns: "repeat(1, 1fr)",
            }}
          >
            <SearchArea
              onChange={(value: string) => setUserInput(value)}
              isLoading={isLoading}
            />
          </Section>

          <hr
            style={{
              padding: "45px",
              paddingTop: "25px",
              margin: "0 45px",
            }}
          />
          <h2
            style={{
              paddingLeft: "40px",
              fontSize: "30px",
              lineHeight: "36px",
            }}
          >
            {" "}
            Refine your search{" "}
          </h2>

          <Section
            refProp={(el: any) => (sectionRefs.current[1] = el)}
            onView={() => setTabIndex(1)}
            sectionName="What kind of project are you working on?"
            setIsInView={scrollIntoViewFn(1)}
          >
            <Tile
              title="New Construction"
              imageUri={NewConstruction}
              isActivated={projectType === ProjectTypeEnum.NEW_CONSTRUCTION}
              setActivation={singleSelect(
                ProjectTypeEnum.NEW_CONSTRUCTION,
                setProjectType
              )}
            />
            <Tile
              title="Retrofit"
              imageUri={Retrofit}
              isActivated={projectType === ProjectTypeEnum.RETROFIT}
              setActivation={singleSelect(
                ProjectTypeEnum.RETROFIT,
                setProjectType
              )}
            />
          </Section>

          <Section
            refProp={(el: any) => (sectionRefs.current[2] = el)}
            onView={() => setTabIndex(2)}
            sectionName="What is your budget?"
            style={{
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
            setIsInView={scrollIntoViewFn(2)}
          >
            <Tile
              title="$"
              isActivated={budget.includes(BudgetEnum.LOW)}
              setActivation={mutiSelect(BudgetEnum.LOW, setBudget)}
            />
            <Tile
              title="$$"
              isActivated={budget.includes(BudgetEnum.MEDIUM)}
              setActivation={mutiSelect(BudgetEnum.MEDIUM, setBudget)}
            />
            <Tile
              title="$$$"
              isActivated={budget.includes(BudgetEnum.HIGH)}
              setActivation={mutiSelect(BudgetEnum.HIGH, setBudget)}
            />
          </Section>
          <Section
            refProp={(el: any) => (sectionRefs.current[3] = el)}
            onView={() => setTabIndex(3)}
            sectionName="What kind color options do you need?"
            style={{
              gridTemplateColumns: "repeat(4, 1fr)",
            }}
            setIsInView={scrollIntoViewFn(3)}
          >
            <Tile
              title="Standard"
              isActivated={colorOptions.includes(ColorOptionsEnum.STANDARD)}
              imageStyles={{
                width: "60px",
              }}
              imageUri={standardColors}
              setActivation={mutiSelect(
                ColorOptionsEnum.STANDARD,
                setColorOptions
              )}
            />
            <Tile
              title="Premium"
              isActivated={colorOptions.includes(ColorOptionsEnum.PREMIUM)}
              imageUri={premiumColors}
              setActivation={mutiSelect(
                ColorOptionsEnum.PREMIUM,
                setColorOptions
              )}
              imageStyles={{
                width: "60px",
              }}
            />
            <Tile
              title="Custom"
              isActivated={colorOptions.includes(ColorOptionsEnum.CUSTOM)}
              imageUri={customColors}
              setActivation={mutiSelect(
                ColorOptionsEnum.CUSTOM,
                setColorOptions
              )}
              imageStyles={{
                width: "60px",
              }}
            />
            <Tile
              title="Wood Finish"
              isActivated={colorOptions.includes(ColorOptionsEnum.WOODFINISH)}
              imageUri={woodFinish}
              setActivation={mutiSelect(
                ColorOptionsEnum.WOODFINISH,
                setColorOptions
              )}
              imageStyles={{
                width: "60px",
              }}
            />
          </Section>
          <Section
            refProp={(el: any) => (sectionRefs.current[4] = el)}
            onView={() => setTabIndex(4)}
            sectionName="Where are you located?"
            setIsInView={scrollIntoViewFn(4)}
          >
            <Tile
              title="Midwest"
              imageUri={MW}
              isActivated={location === LocationEnum.MIDWEST}
              setActivation={singleSelect(LocationEnum.MIDWEST, setLocation)}
            />
            <Tile
              title="Northeast"
              imageUri={NE}
              isActivated={location === LocationEnum.NORTHEAST}
              setActivation={singleSelect(LocationEnum.NORTHEAST, setLocation)}
            />
            <Tile
              title="Coastal Southeast"
              imageUri={CSE}
              isActivated={location === LocationEnum.COASTAL_SOUTHEAST}
              setActivation={singleSelect(
                LocationEnum.COASTAL_SOUTHEAST,
                setLocation
              )}
            />
            <Tile
              title="Southwest"
              imageUri={SW}
              isActivated={location === LocationEnum.SOUTHWEST}
              setActivation={singleSelect(LocationEnum.SOUTHWEST, setLocation)}
            />
            <Tile
              title="Pacific Northwest"
              imageUri={PNW}
              isActivated={location === LocationEnum.PACIFIC_NORTHWEST}
              setActivation={singleSelect(
                LocationEnum.PACIFIC_NORTHWEST,
                setLocation
              )}
            />
            <Tile
              title="Southeast"
              imageUri={SE}
              isActivated={location === LocationEnum.SOUTHEAST}
              setActivation={singleSelect(LocationEnum.SOUTHEAST, setLocation)}
            />
          </Section>
          <Section
            refProp={(el: any) => (sectionRefs.current[5] = el)}
            onView={() => setTabIndex(5)}
            sectionName="What type of window are you looking for?"
            setIsInView={scrollIntoViewFn(5)}
          >
            <Tile
              title="Awning"
              imageUri={Awning}
              isActivated={windowType.includes(WindowTypeEnum.AWNING)}
              setActivation={mutiSelect(WindowTypeEnum.AWNING, setWindowType)}
            />
            <Tile
              title="Single or Double-Hung"
              imageUri={DoubleHung}
              isActivated={windowType.includes(WindowTypeEnum.SINGLE_OR_DOUBLE)}
              setActivation={mutiSelect(
                WindowTypeEnum.SINGLE_OR_DOUBLE,
                setWindowType
              )}
            />
            <Tile
              title="Casement"
              imageUri={Casement}
              isActivated={windowType.includes(WindowTypeEnum.CASEMENT)}
              setActivation={mutiSelect(WindowTypeEnum.CASEMENT, setWindowType)}
            />
            <Tile
              title="Bay & Bow"
              imageUri={BowAndBow}
              isActivated={windowType.includes(WindowTypeEnum.BAY_N_BOW)}
              setActivation={mutiSelect(
                WindowTypeEnum.BAY_N_BOW,
                setWindowType
              )}
            />
            <Tile
              title="Pass-Thru"
              imageUri={Passthru}
              isActivated={windowType.includes(WindowTypeEnum.PASS_THRU)}
              setActivation={mutiSelect(
                WindowTypeEnum.PASS_THRU,
                setWindowType
              )}
            />
            <Tile
              title="Gliding"
              imageUri={Double}
              isActivated={windowType.includes(WindowTypeEnum.GLIDING)}
              setActivation={mutiSelect(WindowTypeEnum.GLIDING, setWindowType)}
            />
            <Tile
              title="Specialty"
              imageUri={Specialty}
              isActivated={windowType.includes(WindowTypeEnum.SPECIALTY)}
              setActivation={mutiSelect(
                WindowTypeEnum.SPECIALTY,
                setWindowType
              )}
            />
            <Tile
              title="Picture"
              imageUri={Picture}
              isActivated={windowType.includes(WindowTypeEnum.PICTURE)}
              setActivation={mutiSelect(WindowTypeEnum.PICTURE, setWindowType)}
            />
          </Section>
        </div>
      </div>
      {/* @ts-ignore */}
      <div className={styles.rightSide} ref={rightSideRef}>
        <div
        className={styles.innerRightSide}
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            gap: "20px",
            alignItems: "center",
            maxWidth: '1000px',
          }}
          key={"right-side"}
        >
          <RecommandationContext
            isLoaded={!isLoading}
            reasoning={reasoning}
            key={"Recommenation context"}
            heroResult={heroResult}
          />
        {!!heroResult && <h3>More great options for you</h3>}
        <div className={styles.suggestions}>
          {(windowResults ?? [{}, {}, {}, {}, {}, {}]).map(
            //@ts-ignore
            (value: APIResult) => (
              <WindowSuggestion
                key={value.title}
                isLoaded={!isLoading}
                result={value}
              />
            )
          )}
        </div>
        {!isLoading && windowResults && windowResults?.length > 0 && (
          <FeelingStuck />
        )}

        </div>
      </div>

    </main>
  );
}